import axios from '@/plugins/axios'
import axiosCustomer from '@/plugins/axios-customer'

export default {
  async list(data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`customer-tasks?${params}`)
  },

  async get(id, isCustomer = false) {
    return await (isCustomer ? axiosCustomer : axios).get(`customer-tasks/${id}`)
  },

  async insert(data) {
    return await axios.post('customer-tasks', data)
  },

  async update(id, data) {
    return await axios.put(`customer-tasks/${id}`, data)
  },

  async delete(id) {
    return await axios.delete(`customer-tasks/${id}`)
  },

  async completed(id, data) {
    return await axios.patch(`customer-tasks/${id}/completed`, data)
  },
}
